<template>
    <component :is="props.as" :class="iconClass" role="img" :aria-label />
</template>
<script setup lang="ts">
import type { AsTag } from "radix-vue";
import type { HTMLAttributes } from "vue";

const props = withDefaults(
    defineProps<{
        class?: HTMLAttributes["class"];
        as?: AsTag;
        name: IconName;
    }>(),
    { class: "", as: "span" }
);

const iconClass = computed(() => cn(iconNameClasses[props.name], props.class));
const ariaLabel = computed(() => props.name.replaceAll("-", " "));
</script>
<script lang="ts">
// normally we put constants elsewhere, but this will keep all icon data together
export const ICON_NAMES = [
    "add",
    "arrow-down",
    "arrow-left",
    "arrow-right",
    "arrows-alt",
    "arrows-rotate",
    "ban",
    "bars",
    "bell",
    "cancel",
    "caret-down",
    "caret-up",
    "cart-shopping",
    "check",
    "check-circle",
    "chevron-down",
    "chevron-left",
    "chevron-right",
    "chevron-up",
    "circle-check",
    "circle-exclamation",
    "circle-info",
    "circle-minus",
    "circle-plus",
    "circle-question",
    "circle-xmark",
    "clipboard",
    "clock",
    "clock-four",
    "close",
    "cloud-arrow-down",
    "cloud-download",
    "cloud-download-alt",
    "cog",
    "copy",
    "edit",
    "exclamation-circle",
    "exclamation-triangle",
    "external-link-alt",
    "eye",
    "filter",
    "floppy-disk",
    "gear",
    "globe",
    "image",
    "info-circle",
    "magnifying-glass",
    "mail-forward",
    "microchip",
    "minus",
    "minus-circle",
    "multiply",
    "navicon",
    "paper-plane",
    "pen-to-square",
    "pencil",
    "pencil-alt",
    "plus",
    "plus-circle",
    "question-circle",
    "redo-alt",
    "refresh",
    "remove",
    "rotate-forward",
    "rotate-right",
    "save",
    "search",
    "share",
    "shopping-cart",
    "sort",
    "sort-asc",
    "sort-desc",
    "sort-down",
    "sort-up",
    "subtract",
    "sync",
    "times",
    "times-circle",
    "trash-alt",
    "trash-can",
    "triangle-exclamation",
    "unsorted",
    "up-down-left-right",
    "up-right-from-square",
    "user",
    "warning",
    "xmark",
    "xmark-circle",
] as const;

export type IconName = (typeof ICON_NAMES)[number];

const iconNameClasses: Record<IconName, string> = {
    add: "fas fa-add",
    "arrow-down": "fas fa-arrow-down",
    "arrow-left": "fas fa-arrow-left",
    "arrow-right": "fas fa-arrow-right",
    "arrows-alt": "fass fa-arrows-alt",
    "arrows-rotate": "fass fa-arrows-rotate",
    ban: "fas fa-ban",
    bars: "fas fa-bars",
    bell: "fas fa-bell",
    cancel: "fas fa-cancel",
    "caret-down": "fas fa-caret-down",
    "caret-up": "fas fa-caret-up",
    "cart-shopping": "fas fa-cart-shopping",
    check: "fass fa-check",
    "check-circle": "fass fa-check-circle",
    "chevron-down": "fass fa-chevron-down",
    "chevron-left": "fass fa-chevron-left",
    "chevron-right": "fass fa-chevron-right",
    "chevron-up": "fass fa-chevron-up",
    "circle-check": "fass fa-circle-check",
    "circle-exclamation": "fas fa-circle-exclamation",
    "circle-info": "fas fa-circle-info",
    "circle-minus": "fas fa-circle-minus",
    "circle-plus": "fas fa-circle-plus",
    "circle-question": "fas fa-circle-question",
    "circle-xmark": "fass fa-circle-xmark",
    clipboard: "fas fa-clipboard",
    clock: "fas fa-clock",
    "clock-four": "fas fa-clock-four",
    close: "fass fa-close",
    "cloud-arrow-down": "fas fa-cloud-arrow-down",
    "cloud-download": "fas fa-cloud-download",
    "cloud-download-alt": "fas fa-cloud-download-alt",
    cog: "fas fa-cog",
    copy: "fas fa-copy",
    edit: "fas fa-edit",
    "exclamation-circle": "fas fa-exclamation-circle",
    "exclamation-triangle": "fas fa-exclamation-triangle",
    "external-link-alt": "fas fa-external-link-alt",
    eye: "fas fa-eye",
    filter: "fas fa-filter",
    "floppy-disk": "fas fa-floppy-disk",
    gear: "fas fa-gear",
    globe: "fas fa-globe",
    image: "fass fa-image",
    "info-circle": "fas fa-info-circle",
    "magnifying-glass": "fas fa-magnifying-glass",
    "mail-forward": "fas fa-mail-forward",
    microchip: "fas fa-microchip",
    minus: "fas fa-minus",
    "minus-circle": "fas fa-minus-circle",
    multiply: "fass fa-multiply",
    navicon: "fas fa-navicon",
    "paper-plane": "fass fa-paper-plane",
    "pen-to-square": "fas fa-pen-to-square",
    pencil: "fass fa-pencil",
    "pencil-alt": "fass fa-pencil-alt",
    plus: "fass fa-plus",
    "plus-circle": "fas fa-plus-circle",
    "question-circle": "fas fa-question-circle",
    "redo-alt": "fass fa-redo-alt",
    refresh: "fass fa-refresh",
    remove: "fass fa-remove",
    "rotate-forward": "fass fa-rotate-forward",
    "rotate-right": "fass fa-rotate-right",
    save: "fas fa-save",
    search: "fas fa-search",
    share: "fas fa-share",
    "shopping-cart": "fas fa-shopping-cart",
    sort: "fass fa-sort",
    "sort-asc": "fass fa-sort-asc",
    "sort-desc": "fass fa-sort-desc",
    "sort-down": "fass fa-sort-down",
    "sort-up": "fass fa-sort-up",
    subtract: "fas fa-subtract",
    sync: "fass fa-sync",
    times: "fass fa-times",
    "times-circle": "fass fa-times-circle",
    "trash-alt": "fas fa-trash-alt",
    "trash-can": "fas fa-trash-can",
    "triangle-exclamation": "fas fa-triangle-exclamation",
    unsorted: "fass fa-unsorted",
    "up-down-left-right": "fass fa-up-down-left-right",
    "up-right-from-square": "fas fa-up-right-from-square",
    user: "fas fa-user",
    warning: "fas fa-warning",
    xmark: "fass fa-xmark",
    "xmark-circle": "fass fa-xmark-circle",
} as const;
</script>
